import React from "react"

import { graphql } from "gatsby"

import SliceSection from "~/components/slices/common/SliceSection/SliceSection"
import { PrismicSlice } from "~/components/slices/common/SliceTypes"
import Workshop, { WorkshopProps } from "~/components/slices/Workshop/Workshop"
import { PartialRecipeWithVideo } from "~/models/PartialRecipe"

interface PropTypes extends PrismicWorkshopPageDataBodyWorkshop {
  recipes?: PartialRecipeWithVideo[]
}

function WorkshopBodyWorkshop({ primary, recipes, ...props }: PropTypes) {
  if (!primary.date) return null

  return (
    <SliceSection width={[12, 6]} margin="small" {...props}>
      <Workshop {...{ ...primary, recipes }} />
    </SliceSection>
  )
}

export default WorkshopBodyWorkshop

export interface PrismicWorkshopPageDataBodyWorkshop extends PrismicSlice {
  sliceType: "workshop"
  primary: WorkshopProps
}

export const query = graphql`
  fragment PrismicWorkshopPageDataBodyWorkshop on PrismicWorkshopPageDataBodyWorkshop {
    id
    __typename
    sliceType: slice_type
    sliceLabel: slice_label
    primary {
      recipeUid: recipe_uid
      image {
        fluid(maxWidth: 350) {
          ...GatsbyImgixFluid_noBase64
        }
      }
      name {
        ...PrismicStructuredText
      }
      date
      duration
      time
      color
      eventIsFull: event_is_full
      registerLink: register_link {
        ...PrismicLink
      }
      waitlistLink: waitlist_link {
        ...PrismicLink
      }
      organizer {
        document {
          ... on PrismicPerson {
            data {
              displayName: display_name {
                ...PrismicStructuredText
              }
              profilePicture: profile_picture {
                fluid(maxWidth: 100) {
                  ...GatsbyImgixFluid_noBase64
                }
              }
            }
          }
        }
      }
    }
  }
`
