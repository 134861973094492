import React from "react"

import SliceSection from "~/components/slices/common/SliceSection/SliceSection"
import { PrismicSlice } from "~/components/slices/common/SliceTypes"
import Text, { TextProps } from "~/components/slices/Text/Text"

interface PropTypes extends PrismicWorkshopBodyText {
  isDarkTheme?: boolean
}

function WorkshopBodyText({ primary, isDarkTheme, ...props }: PropTypes) {
  const usedAsTitle = !primary.text.text
  return (
    <SliceSection margin={usedAsTitle ? "none" : "large"} {...props}>
      <Text {...{ ...props, ...primary }} />
    </SliceSection>
  )
}

export default WorkshopBodyText

export interface PrismicWorkshopBodyText extends PrismicSlice {
  sliceType: "text"
  primary: TextProps
}

// NOTE: Currently this slice is added programmatically, not fetched from Prismic
// THe following GraphQL doesn't not exists in workshop page yet
// But it can be duplicated from the Landings, and it will be correct.

// export const query = graphql`
//   fragment PrismicWorkshopBodyText on PrismicWorkshopBodyText {
//     id
//     __typename
//     sliceType: slice_type
//     sliceLabel: slice_label
//     primary {
//       textAlign: text_align
//       title {
//         ...PrismicStructuredText
//       }
//       subtitle {
//         ...PrismicStructuredText
//       }
//       text {
//         ...PrismicStructuredText
//       }
//     }
//   }
// `
