import React from "react"

import { graphql } from "gatsby"

import SliceSection from "~/components/slices/common/SliceSection/SliceSection"
import { PrismicSlice } from "~/components/slices/common/SliceTypes"
import ImageBanner, {
  ImageBannerProps,
} from "~/components/slices/ImageBanner/ImageBanner"

type PropTypes = PrismicWorkshopPageDataBodyImageBanner

function WorkshopBodyImageBanner({ primary, ...props }: PropTypes) {
  return (
    <SliceSection margin="small" {...props}>
      <ImageBanner {...{ ...primary, ...props }} />
    </SliceSection>
  )
}

export default WorkshopBodyImageBanner

export interface PrismicWorkshopPageDataBodyImageBanner extends PrismicSlice {
  sliceType: "image_banner"
  primary: ImageBannerProps
}

export const query = graphql`
  fragment PrismicWorkshopPageDataBodyImageBanner on PrismicWorkshopPageDataBodyImageBanner {
    id
    __typename
    sliceType: slice_type
    sliceLabel: slice_label
    primary {
      link {
        ...PrismicLink
      }
      bannerHeight: banner_height
      desktopImage: desktop_image {
        fluid(maxWidth: 1000) {
          ...GatsbyImgixFluid_noBase64
        }
        thumbnails {
          High {
            fluid(maxWidth: 1000) {
              ...GatsbyImgixFluid_noBase64
            }
          }
          SixteenByNine {
            fluid(maxWidth: 1000) {
              ...GatsbyImgixFluid_noBase64
            }
          }
        }
      }
      mobileImage: mobile_image {
        fluid(maxWidth: 728) {
          ...GatsbyImgixFluid_noBase64
        }
        thumbnails {
          High {
            fluid(maxWidth: 728) {
              ...GatsbyImgixFluid_noBase64
            }
          }
          SixteenByNine {
            fluid(maxWidth: 728) {
              ...GatsbyImgixFluid_noBase64
            }
          }
        }
      }
    }
  }
`
