import React, { CSSProperties } from "react"

import { FluidObject } from "gatsby-image"
import { FormattedMessage, useIntl } from "react-intl"

import calendarIcon from "./calendar.svg"
import clockIcon from "./clock.svg"
import hourglassIcon from "./hourglass.svg"
import ColorableSVG from "~/components/ColorableSVG/ColorableSVG"
import Link from "~/components/Link/Link"
import {
  Body,
  Col,
  Heading,
  HrefButton,
  ImageCard,
  ImageCardOverlay,
  Row,
} from "~/components/ui"
import { PartialRecipeWithVideo } from "~/models/PartialRecipe"
import { PrismicLink, PrismicStructuredText } from "~/models/PrismicTypes"
import Vertical from "~/models/Vertical"
import { getPrismicLinkProps } from "~/utils/PrismicLink"

import css from "./Workshop.module.scss"

interface WorkshopMetaProps {
  href: string
  color: CSSProperties["color"]
  text: string | null
}

const WorkshopMeta = ({ href, color, text }: WorkshopMetaProps) => (
  <div className={css.meta}>
    <ColorableSVG
      width={16}
      height={16}
      mainColor={color}
      href={href}
      title={text || ""}
    />
    <Body noMargin variant="body3">
      {text || ""}
    </Body>
  </div>
)

interface PrismicTeamMember {
  id: string
  data: {
    displayName: PrismicStructuredText
    profilePicture: { fluid: FluidObject | null }
  }
}

const WorkshopOrganizer = ({
  profilePicture,
  displayName,
}: PrismicTeamMember["data"]) => (
  <div className={css.organizer}>
    <span
      className={css.organizerAvatar}
      style={{
        backgroundImage: `url(${profilePicture.fluid?.src || ""})`,
      }}
    />
    <Body variant="body3" noMargin>
      <FormattedMessage id="text:by" /> {displayName.text || ""}
    </Body>
  </div>
)

export interface WorkshopProps {
  recipeUid: string | null
  image: {
    alt: string | null
    fluid: FluidObject | null
  }
  name: PrismicStructuredText
  date: string | null
  duration: string | null
  time: string | null
  color: CSSProperties["color"] | null
  eventIsFull: boolean
  registerLink: PrismicLink
  waitlistLink: PrismicLink
  organizer: {
    document: null | PrismicTeamMember
  }
}

interface PropTypes extends WorkshopProps {
  recipes?: PartialRecipeWithVideo[]
}

function Workshop({
  recipeUid,
  image,
  name,
  date,
  duration,
  time,
  eventIsFull,
  registerLink,
  waitlistLink,
  organizer,
  recipes,
  ...props
}: PropTypes) {
  const intl = useIntl()

  const jsDate = date && new Date(date)
  const DAY = 24 * 60 * 60 * 1000
  const isPast = jsDate && jsDate.getTime() < Date.now() - DAY
  const defaultColor = Vertical.Original.color
  const color: CSSProperties["color"] = props.color || defaultColor
  const linkProps = getPrismicLinkProps(
    eventIsFull ? waitlistLink : registerLink
  )
  const currentRecipe =
    recipes && recipes.find(recipe => recipe.id === recipeUid)

  if (!linkProps?.href) {
    return null
  }

  const overlayProps: ImageCardOverlay = isPast
    ? { type: "workshop", payload: "completed" }
    : eventIsFull
    ? { type: "workshop", payload: "full" }
    : { type: "default" }

  let ImageComponent = (
    <ImageCard disableHover overlay={{ type: "not-found" }} />
  )

  if (recipeUid === null) {
    if (image.fluid) {
      ImageComponent = (
        <ImageCard
          overlay={overlayProps}
          disableHover
          imageProps={{
            src: image.fluid.src,
          }}
        />
      )
    }
  } else {
    ImageComponent = (
      <Link to="recipes/recipe" params={currentRecipe}>
        <ImageCard
          overlay={overlayProps}
          imageProps={{
            src: currentRecipe?.webCover || "",
          }}
        />
      </Link>
    )
  }

  return (
    <article
      className={css.card}
      style={{ "--current-workshop-color": color } as CSSProperties}
    >
      <Row>
        <Col width={6}>{ImageComponent}</Col>
        <Col width={6}>
          <Heading noMargin Tag="h3" variant="small">
            {name.text}
          </Heading>

          {organizer.document && (
            <WorkshopOrganizer {...organizer.document.data} />
          )}

          {date && (
            <WorkshopMeta
              href={calendarIcon}
              text={intl.formatDate(new Date(date), {
                month: "long",
                day: "numeric",
                weekday: "long",
              })}
              color={color}
            />
          )}

          <WorkshopMeta href={clockIcon} text={time} color={color} />

          <WorkshopMeta href={hourglassIcon} text={duration} color={color} />
        </Col>
      </Row>

      {!isPast && (
        <HrefButton
          variant={eventIsFull ? "secondary" : "primary"}
          className={css.button}
          color={color}
          fullWidth
          {...linkProps}
        >
          {eventIsFull ? (
            <FormattedMessage id="workshop/action:notify-in-case-of-withdrawal" />
          ) : (
            <FormattedMessage id="workshop/action:register-for-the-workshop" />
          )}
        </HrefButton>
      )}
    </article>
  )
}

export default Workshop
