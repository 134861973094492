import { useState } from "react"

import { graphql, useStaticQuery } from "gatsby"

import useIsomorphicLayoutEffect from "~/hooks/useIsomorphicLayoutEffect"

export default function useDate() {
  const data = useStaticQuery(graphql`
    query siteBuildTime {
      site {
        buildTime
      }
    }
  `)
  const buildDate = new Date(data.site.buildTime)

  const [date, setDate] = useState(buildDate)

  useIsomorphicLayoutEffect(() => {
    setDate(new Date())
  }, [])

  return date
}
