//  - Collect all workshop slices in an array
//  - Create page body template by replacing workshops by blank spaces
//  - Sort workshops by date; filter out old workshops
//  - Complete the template by inserting ordered/filtered workshops

import { PrismicWorkshopBodyText } from "./slices/WorkshopBodyText"
import { PrismicWorkshopPageDataBodyWorkshop } from "./slices/WorkshopBodyWorkshop"
import { AnyPrismicWorkshopSlice } from "./WorkshopSlice"
import { PrismicStructuredText } from "~/models/PrismicTypes"

type Template = (AnyPrismicWorkshopSlice | null)[]
type Workshop = PrismicWorkshopPageDataBodyWorkshop
type Slice = AnyPrismicWorkshopSlice

interface ExplodedSlices {
  upcomingWorkshops: Workshop[]
  pastWorkshops: Workshop[]
  pageTemplate: Template
}

function explodeWorkshopSlices(
  slices: Slice[],
  referenceDate: Date
): ExplodedSlices {
  const output: ExplodedSlices = {
    upcomingWorkshops: [],
    pastWorkshops: [],
    pageTemplate: [],
  }

  for (const slice of slices) {
    if (slice.sliceType === "workshop") {
      const date = new Date(slice.primary?.date || 0)
      const DAY = 24 * 60 * 60 * 1000
      const isPast = date.getTime() < referenceDate.getTime() - DAY

      if (isPast) {
        output.pastWorkshops.push(slice)
      } else {
        output.upcomingWorkshops.push(slice)
      }
      output.pageTemplate.push(null)
    } else {
      output.pageTemplate.push(slice)
    }
  }

  return output
}

function sortWorkshopsByDate(
  slices: Workshop[],
  order: "ASC" | "DESC"
): Workshop[] {
  return slices.sort((a, b) => {
    // Hacky fallback because data are coming from Prismic, there can be empty
    const aDate = new Date(a.primary?.date || 0)
    const bDate = new Date(b.primary?.date || 0)

    return order === "ASC"
      ? aDate.getTime() - bDate.getTime()
      : bDate.getTime() - aDate.getTime()
  })
}

function completeTemplateWithSortedWorkshop(
  template: Template,
  incomingWorkshops: Workshop[],
  passedWorkshops: Workshop[],
  passedTitleSlice: Slice
): Slice[] {
  // Duplicate arrays to use mutation methods keeping the function pure
  const incoming = [...incomingWorkshops]
  const passed = [...passedWorkshops]

  const slices: Slice[] = []

  template.forEach(slice => {
    if (slice) {
      // Use slice already in place
      slices.push(slice)
    } else {
      // Blank space: insert next unused workshop slice
      if (incoming.length > 0) {
        const workshop = incoming.shift()
        slices.push(workshop as Workshop)
      } else if (passed.length > 0) {
        // Before the first passed, push the title slice
        if (!slices.includes(passedTitleSlice)) {
          slices.push(passedTitleSlice)
        }
        const workshop = passed.shift()
        slices.push(workshop as Workshop)
      }
    }
  })

  return slices
}

function toPrismicText(text: string): PrismicStructuredText {
  return {
    text,
    raw: [
      {
        type: "paragraph",
        text: text,
        spans: [],
      },
    ],
    html: `<p>${text}</p>`,
  }
}

function createTextSlice(title: string): PrismicWorkshopBodyText {
  return {
    __typename: "PrismicWorkshopPageBodyText",
    id: Date.now().toString(),
    sliceType: "text",
    sliceLabel: null,
    primary: {
      title: toPrismicText(title),
      titleColor: undefined,
      text: toPrismicText(""),
      subtitle: toPrismicText(""),
      textAlign: "left",
      hasSeparator: false,
      buttonLink: null,
      buttonLabel: toPrismicText(""),
      buttonStyle: "Prominent",
      buttonColor: null,
    },
  }
}

export function sortWorkshopSlices(
  slices: Slice[],
  pastTitle: string,
  referenceDate: Date
): Slice[] {
  const { pageTemplate, upcomingWorkshops, pastWorkshops } =
    explodeWorkshopSlices(slices, referenceDate)

  const sortedUpcomingWorkshops = sortWorkshopsByDate(upcomingWorkshops, "ASC")
  const sortedPastWorkshops = sortWorkshopsByDate(pastWorkshops, "DESC")
  const titleSlice = createTextSlice(pastTitle)

  const MAX_PAST_WORKSHOPS = 10

  return completeTemplateWithSortedWorkshop(
    pageTemplate,
    sortedUpcomingWorkshops,
    sortedPastWorkshops.slice(0, MAX_PAST_WORKSHOPS + 1),
    titleSlice
  )
}
