import React from "react"

import { FormattedMessage } from "react-intl"

import { Body, Container, HrefButton } from "~/components/ui"

import css from "./StickyBanner.module.scss"

function StickyBanner() {
  return (
    <div className={css.stickBanner}>
      <Container>
        <div className={css.wrapper}>
          <Body
            className={css.text}
            variant="body2"
            color="white"
            noMargin
            semiBold
          >
            <FormattedMessage id="workshop/text:receive-next-workshops" />
          </Body>
          <HrefButton
            className={css.button}
            href="https://chefclub-community.typeform.com/to/s7TKRlSV"
            color="original"
          >
            <FormattedMessage id="workshop/action:notify-me" />
          </HrefButton>
        </div>
      </Container>
    </div>
  )
}

export default StickyBanner
