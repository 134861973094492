import React, { useMemo } from "react"

import { graphql } from "gatsby"
import { FormattedMessage, useIntl } from "react-intl"

import withTemplate, { TemplateDefaultPropTypes } from "../withTemplate"
import { sortWorkshopSlices } from "./sortWorkshops"
import WorkshopSlice, { AnyPrismicWorkshopSlice } from "./WorkshopSlice"
import SEO from "~/components/Seo/Seo"
import { SliceVideoControls } from "~/components/slices/common/SliceTypes"
import { getVideoControlsProps } from "~/components/slices/common/SliceUtils"
import { Body, Col, Container, Heading, Row } from "~/components/ui"
import VideoPlayer from "~/components/VideoPlayer/VideoPlayer"
import useDate from "~/hooks/useDate"
import usePrismicPreview from "~/hooks/usePrismicPreview"
import { PartialRecipeWithVideo } from "~/models/PartialRecipe"
import { PrismicStructuredText } from "~/models/PrismicTypes"
import { Video } from "~/models/Video"
import StickyBanner from "~/templates/Workshop/StickyBanner/StickyBanner"

import css from "./Workshop.module.scss"
interface PropTypes extends TemplateDefaultPropTypes {
  data: WorkshopQuery
  pageContext: TemplateDefaultPropTypes["pageContext"]
}

function Workshop(props: PropTypes) {
  const intl = useIntl()
  const [data /*, isPreview*/] = usePrismicPreview(props.data)
  const {
    metaTitle,
    metaDescription,
    metaImage,
    pageTitle,
    pageSubtitle,
    pageDescription,
    videoUid,
    videoControls,
    body,
  } = data.prismicWorkshopPage.data
  const referenceDate = useDate()
  const currentVideo = data.allVideo.nodes.find(el => el.id === videoUid)

  // Re-sort the workshop list by date when the date changes
  const slices = useMemo(
    () =>
      sortWorkshopSlices(
        body,
        intl.formatMessage({ id: "workshop/section:past-workshops" }),
        referenceDate
      ),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [referenceDate]
  )

  return (
    <>
      <SEO
        title={metaTitle}
        description={metaDescription}
        image={metaImage?.url}
        location={props.location}
      />

      <div className={css.root}>
        <Container>
          <Row>
            {currentVideo && (
              <Col width={[12, 5]}>
                <div className={css.videoWrapper}>
                  <VideoPlayer
                    poster={currentVideo.videoPreview}
                    src={currentVideo.hostedVideoUrl}
                    verticalColor={"#74CDFF"}
                    format="square"
                    videoFit="contain"
                    {...getVideoControlsProps(videoControls)}
                  />
                </div>
              </Col>
            )}
            <Col width={[12, currentVideo ? 7 : 12]}>
              <div className={css.headingWrapper}>
                <Heading
                  className={css.pageTitle}
                  Tag="h1"
                  variant="extraLarge"
                  noMargin
                >
                  {pageTitle.text}
                </Heading>
                <Heading variant="extraSmall" noMargin>
                  {pageSubtitle.text}
                </Heading>
                <Body variant="body1">{pageDescription.text}</Body>
              </div>
            </Col>
          </Row>

          <Heading className={css.bodyTitle} Tag="h2" variant="large">
            <FormattedMessage id="workshop/section:upcoming-workshops" />
          </Heading>
        </Container>

        <WorkshopSlice body={slices} recipes={data.allRecipe.nodes} />

        <StickyBanner />
      </div>
    </>
  )
}

type ArrayOfNodes<T> = { nodes: T[] }

interface WorkshopQuery {
  allRecipe: ArrayOfNodes<PartialRecipeWithVideo>
  allVideo: ArrayOfNodes<Video>
  prismicWorkshopPage: {
    data: {
      metaTitle: string
      metaDescription: string
      metaImage?: { url: string }
      pageTitle: PrismicStructuredText
      pageSubtitle: PrismicStructuredText
      pageDescription: PrismicStructuredText
      videoUid: string | null
      videoControls: SliceVideoControls
      body: AnyPrismicWorkshopSlice[]
    }
  }
}

export const query = graphql`
  query WorkshopQuery(
    $lang: String
    $workshopId: ID
    $recipeIds: [String]
    $videoIds: [String]
  ) {
    prismicWorkshopPage(lang: { eq: $lang }, prismicId: { eq: $workshopId }) {
      data {
        metaTitle: meta_title
        metaDescription: meta_description
        metaImage: meta_image {
          url
        }
        videoUid: video_uid
        videoControls: video_controls
        pageTitle: page_title {
          ...PrismicStructuredText
        }
        pageSubtitle: page_subtitle {
          ...PrismicStructuredText
        }
        pageDescription: page_description {
          ...PrismicStructuredText
        }
        ...PrismicWorkshopPageBody
      }
    }

    allRecipe(filter: { id: { in: $recipeIds } }) {
      nodes {
        ...PartialRecipeWithVideo
      }
    }

    allVideo(filter: { id: { in: $videoIds } }) {
      nodes {
        ...Video
      }
    }
  }
`

export default withTemplate(Workshop)
