import React from "react"

import { graphql } from "gatsby"

import SliceSection from "~/components/slices/common/SliceSection/SliceSection"
import { PrismicSlice } from "~/components/slices/common/SliceTypes"
import FormattableText, {
  FormattableTextProps,
} from "~/components/slices/FormattableText"

type PropTypes = PrismicWorkshopPageDataBodyFormattableText

function WorkshopBodyFormattableText(props: PropTypes) {
  return (
    <SliceSection {...props}>
      <FormattableText {...props.primary} />
    </SliceSection>
  )
}

export default WorkshopBodyFormattableText

export interface PrismicWorkshopPageDataBodyFormattableText
  extends PrismicSlice {
  sliceType: "formattable_text"
  primary: FormattableTextProps
}

export const query = graphql`
  fragment PrismicWorkshopPageDataBodyFormattableText on PrismicWorkshopPageDataBodyFormattableText {
    id
    __typename
    sliceType: slice_type
    sliceLabel: slice_label
    primary {
      title {
        ...PrismicStructuredText
      }
      text {
        ...PrismicStructuredText
      }
      buttonLabel: button_label {
        ...PrismicStructuredText
      }

      backgroundColor: background_color
      textColor: text_color
      layout

      image {
        dimensions {
          height
          width
        }
        fluid(maxWidth: 100) {
          ...GatsbyImgixFluid_noBase64
        }
      }
      link {
        ...PrismicLink
      }
    }
  }
`
