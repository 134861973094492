import React, { Fragment } from "react"

import { graphql } from "gatsby"

import WorkshopBodyFormattableText, {
  PrismicWorkshopPageDataBodyFormattableText,
} from "./slices/WorkshopBodyFormattableText"
import WorkshopBodyImageBanner, {
  PrismicWorkshopPageDataBodyImageBanner,
} from "./slices/WorkshopBodyImageBanner"
import WorkshopBodyText, {
  PrismicWorkshopBodyText,
} from "./slices/WorkshopBodyText"
import WorkshopBodyWorkshop, {
  PrismicWorkshopPageDataBodyWorkshop,
} from "./slices/WorkshopBodyWorkshop"
import { Container, Row } from "~/components/ui"
// TODO: Using dynamic imports
// I wanted dynamic import like this https://loadable-components.com/docs/dynamic-import/
// There is a gatsby plugin which implements SSR support
// See plugin: https://www.gatsbyjs.com/plugins/gatsby-plugin-loadable-components-ssr/
// But today, there is a bug, dynamic import doesn't work properly with SSR
// See issue: https://github.com/hector-del-rio/gatsby-plugin-loadable-components-ssr/issues/4
import { PartialRecipeWithVideo } from "~/models/PartialRecipe"
import { warnAboutUnexpectedSlice } from "~/utils/general"

export type AnyPrismicWorkshopSlice =
  | PrismicWorkshopPageDataBodyWorkshop
  | PrismicWorkshopPageDataBodyFormattableText
  | PrismicWorkshopPageDataBodyImageBanner
  | PrismicWorkshopBodyText

interface WorkshopSliceAdditionalData {
  recipes?: PartialRecipeWithVideo[]
}

function slices(
  props: AnyPrismicWorkshopSlice,
  { recipes }: WorkshopSliceAdditionalData
) {
  switch (props.sliceType) {
    case "workshop":
      return (
        <WorkshopBodyWorkshop key={props.id} {...props} recipes={recipes} />
      )

    case "formattable_text":
      return <WorkshopBodyFormattableText key={props.id} {...props} />
    case "image_banner":
      return <WorkshopBodyImageBanner key={props.id} {...props} />
    case "text":
      return <WorkshopBodyText key={props.id} {...props} />

    default:
      warnAboutUnexpectedSlice("Workshop", props)
      return null
  }
}

interface PropTypes extends WorkshopSliceAdditionalData {
  body: AnyPrismicWorkshopSlice[]
}

function WorkshopSlice({ body, recipes, ...props }: PropTypes) {
  return (
    <Container>
      <Row>
        {body.map(slice => (
          <Fragment key={slice.id}>
            {slices({ ...slice, ...props }, { recipes })}
          </Fragment>
        ))}
      </Row>
    </Container>
  )
}

export default WorkshopSlice

export const query = graphql`
  fragment PrismicWorkshopPageBody on PrismicWorkshopPageDataType {
    body {
      ...PrismicWorkshopPageDataBodyWorkshop
      ...PrismicWorkshopPageDataBodyFormattableText
      ...PrismicWorkshopPageDataBodyImageBanner
    }
  }
`
